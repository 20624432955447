<template>
  <en-drawer v-model="visible" title="编辑项目">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="项目名称" prop="name">
        <en-input
          v-model="form.data.name"
          :disabled="
            !!form.data.maintenance?.spraySurface ||
            (form.data.inflatedFlag?.code !== 'Y' && !store.accessRightsHash.SERVICE_MAINTENANCE_CUSTOM) ||
            (form.data.inflatedFlag?.code === 'Y' && !store.accessRightsHash.SERVICE_MAINTENANCE_INFLATED)
          "
        ></en-input>
      </en-form-item>
      <en-form-item label="项目编码">
        <en-input :value="form.data.maintenance?.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="工时">
        <en-input-number
          v-model="form.data.laborHour"
          :min="0"
          :disabled="
            disabled ||
            form.data.chargingMethod?.code === 'RWK' ||
            Boolean(document?.id) ||
            (store.attribute.FCMTNCS.value === 'Y' && !store.accessRightsHash.SERVICE_MAINTENANCE_PRICE_EDIT)
          "
          class="w-full"
        ></en-input-number>
      </en-form-item>
      <en-form-item label="单价">
        <en-input-number
          v-model="form.data.price"
          :disabled="
            disabled ||
            form.data.chargingMethod?.code === 'RWK' ||
            Boolean(document?.id) ||
            (store.attribute.FCMTNCS.value === 'Y' && !store.accessRightsHash.SERVICE_MAINTENANCE_PRICE_EDIT)
          "
          :min="0"
          class="w-full"
        ></en-input-number>
      </en-form-item>
      <en-form-item label="计价方式">
        <select-maintain
          v-model="form.data.valuationMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['VLMTD'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          :disabled="disabled || form.data.chargingMethod?.code === 'RWK'"
          @change="form.valuationMethod.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="内部工时">
        <en-input-number
          v-model="form.data.internalLaborHour"
          :min="0"
          :disabled="
            disabled ||
            form.data.valuationMethod?.code === 'P' ||
            (store.attribute.FCMTNCS.value === 'Y' && !store.accessRightsHash.SERVICE_MAINTENANCE_PRICE_EDIT)
          "
          class="w-full"
        ></en-input-number>
      </en-form-item>
      <en-form-item label="收费类别" prop="chargingMethod.code">
        <select-maintain
          v-model="form.data.chargingMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => {
              params.paths = ['VLMTD']
              params.payload = { excludes: ['TNM', 'RMN', 'OTS'] }
            }
          }"
          :props="{ label: 'message', value: '', disabled: (option: EnocloudServiceDefinitions['LookupDto']) => hasFullCarPaints && option.code === 'RWK' }"
          value-key="code"
          :disabled="form.data.chargingMethod?.code === 'RWK'"
          @change="form.chargingMethod.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="派工班组">
        <select-maintain
          v-model="form.data.workingTeam"
          :ajax="{
            action: 'GET /enocloud/common/workingteam',
            params: (params) => (params.payload = { branchIds: branchId ? [Number(branchId)] : [] })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          :disabled="
            disabled || form.data.chargingMethod?.code === 'RMN' || form.data.chargingMethod?.code === 'TNM' || form.data.inflatedFlag?.value
          "
          @change="form.workingTeam.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="派工技师">
        <!-- <select-maintain
          v-model="form.assignees"
          :options="form.data.workingTeam?.members"
          :props="{ label: 'user.name', value: 'user' }"
          allow-create
          multiple
          value-key="id"
          :disabled="
            disabled || form.data.chargingMethod?.code === 'RMN' || form.data.chargingMethod?.code === 'TNM' || form.data.inflatedFlag?.value
          "
          class="w-full"
        ></select-maintain> -->
        <!-- <assignee-select
          v-model="form.data.assignees"
          :options="form.data.workingTeam?.members"
          :disabled="
            disabled || form.data.chargingMethod?.code === 'RMN' || form.data.chargingMethod?.code === 'TNM' || form.data.inflatedFlag?.value
          "
          class="w-full"
        ></assignee-select> -->
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" type="textarea"></en-input>
      </en-form-item>
      <en-form-item label="打印">
        <en-checkbox :model-value="form.data.print?.value" false-label="N" true-label="Y">打印</en-checkbox>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { find, isString } from 'lodash-es'

export default factory({
  props: {
    modelValue: Boolean,
    data: [Object, null] as PropType<EnocloudServiceDefinitions['ServiceMaintenanceDto'] | null>,
    branchId: [String, Number],
    disabled: Boolean,
    document: Object as PropType<EnocloudServiceDefinitions['DocumentDto'] | undefined>,
    customer: Object as PropType<EnocloudServiceDefinitions['CustomerDto'] | undefined>,
    hasFullCarPaints: Boolean
  },

  emits: {
    'update:model-value': (value: number) => typeof value === 'boolean'
  },

  watch: {
    visible: {
      handler(visible) {
        if (visible && this.data) {
          this.form.data = { ...this.data }
        }
      }
    }
  },

  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.emit('update:model-value', value)
      }
    }
  },

  config: {
    children: {
      form: {
        data: {} as EnocloudServiceDefinitions['ServiceMaintenanceDto'],
        rules: {
          name: [{ required: true, message: '请填写项目名称' }],
          'chargingMethod.code': [{ required: true, message: '请选择收费类别' }]
        },
        computed: {
          assignees: {
            get() {
              return this.form.data.assignees.map((item) => {
                return item.assignee || { name: item.name }
              }) as EnocloudServiceDefinitions['UserDto'][]
            },
            set(value: Array<string | EnocloudServiceDefinitions['UserDto']>) {
              this.form.data.assignees = value.map((item) => {
                if (isString(item)) {
                  return {
                    name: item
                  }
                } else {
                  return {
                    id: find(this.form.data.assignees, ['assignee.id', item.id])?.id,
                    name: find(this.form.data.assignees, ['assignee.id', item.id])?.name,
                    assignee: item
                  }
                }
              })
            }
          }
        },
        children: {
          valuationMethod: {
            change() {
              if (this.form.data.valuationMethod?.code === 'P') this.form.data.laborHour = 1
            }
          },
          chargingMethod: {
            change() {
              const { chargingMethod } = this.form.data
              this.form.data.discountRate = chargingMethod?.forceDiscountRate
                ? this.customer?.serviceMaintenanceDiscountRate
                : chargingMethod?.defaultDiscountRate
            }
          },
          workingTeam: {
            change() {
              this.form.data.assignees = []
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.visible = false
            }
          },
          confirm: {
            click() {
         
            }
          }
        }
      }
    }
  }
})
</script>
